<template>
  <v-card>
    <loading-bar :value="loading"/>
    <v-container fluid>
      <v-row dense>
        <!-- Weekly Registrations -->
        <v-col sm="12">
          <v-card>
            <v-toolbar flat dense>
              <v-toolbar-title>Registrations By Week</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="border-right"></th>
                    <th v-for="g in weekly.groups"
                      class="text-center border-right"
                      :key="g.name"
                      :colspan="g.subs.length"
                    >
                      {{g.name}}
                    </th>
                    <th>Total</th>
                  </tr>
                  <tr>
                    <th class="border-right"></th>
                    <template v-for="g in weekly.groups">
                      <th v-for="(s, i) in g.subs"
                        class="text-center"
                        :key="`th-${g.name}-${s}`"
                        :class="{'border-right': i === g.subs.length - 1}"
                      >
                        {{s}}
                      </th>
                    </template>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="w in weekly.weeks" :key="`week-${w}`">
                    <th class="border-right">Week {{w}}</th>
                    <template v-for="g in weekly.groups">
                      <td v-for="(s, i) in g.subs"
                        class="text-center"
                        :key="`td-${g.name}-${s}`"
                        :class="{'border-right': i === g.subs.length - 1}"
                      >
                        {{getWeeklyValue(w, g, s)}}
                      </td>
                    </template>
                    <td
                      class="text-center font-weight-bold"
                    >
                        {{getWeeklyValue(w)}}
                    </td>
                  </tr>
                  <tr>
                    <th class="border-right">Total</th>
                    <template v-for="g in weekly.groups">
                      <td v-for="(s, i) in g.subs"
                        class="text-center font-weight-bold"
                        :key="`td-${g.name}-${s}`"
                        :class="{'border-right': i === g.subs.length - 1}"
                      >
                        {{getTotalValue(g, s)}}
                      </td>
                    </template>
                    <td
                      class="text-center font-weight-bold"
                    >
                        {{tournament.activeTeams.length}}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <!-- Teams By Division -->
        <v-col sm="12" md="6">
          <v-card>
            <v-toolbar flat dense>
              <v-toolbar-title>Teams By Division</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.stop="copy('report1')">
                <v-icon>fal fa-copy</v-icon>
              </v-btn>
            </v-toolbar>
            <v-data-table
              :headers="report.headers"
              :items="report.rows"
              :options.sync="pagination"
              hide-default-footer
              multi-sort
              dense
              ref="report1"
              :items-per-page="-1"
            >
              <template v-slot:[`body.append`]="{headers}">
                <tr class="total">
                  <td
                    v-for="head in headers"
                    :key="head.value"
                    :class="`text-${head.align === 'center' ? 'center' : 'left'}`"
                  >{{report.totals[head.value]}}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <!-- Shirt Sizes -->
        <v-col sm="12" md="6">
          <v-card>
            <v-toolbar flat dense>
              <v-toolbar-title>Shirt Sizes</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.stop="copy('shirts')">
                <v-icon>fal fa-copy</v-icon>
              </v-btn>
            </v-toolbar>
            <v-data-table
              :headers="shirts.headers"
              :items="shirts.rows"
              hide-default-footer
              dense
              ref="shirts"
            >
              <template v-slot:[`body.append`]="{headers}">
                <tr class="total">
                  <td
                    v-for="head in headers"
                    :key="head.value"
                    :class="`text-${head.align === 'center' ? 'center' : 'left'}`"
                  >{{shirts.totals[head.value]}}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import flatten from '@/helpers/ArrayFlatten'
// import { firstBy } from 'thenby'
const arrSum = arr => arr.reduce((a, b) => a + b, 0)

export default {
  props: ['active'],
  data () {
    return {
      loading: false,
      pagination: {
        sortBy: ['gender'],
        sortDesc: [true]
      },
      regData: null
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    report () {
      return this.divisional
    },
    divisional () {
      const result = {
        rows: this.tournament.publicDivisions.map(d => {
          return {
            id: d.id,
            dName: d.name,
            gender: d.gender.name,
            division: d.name,
            teams: d.activeTeams.length,
            waitlistTeams: d.waitlistTeams.length,
            players: arrSum(d.activeTeams.map(t => t.players.length)),
            max: d.maxTeams
          }
        }),
        headers: [
          {
            text: 'Gender',
            sortable: true,
            value: 'gender',
            align: 'start'
          },
          {
            text: 'Division',
            sortable: true,
            value: 'division',
            align: 'start'
          },
          {
            text: 'Teams',
            sortable: true,
            value: 'teams',
            align: 'center'
          },
          {
            text: 'Team Cap',
            sortable: true,
            value: 'max',
            align: 'center'
          },
          {
            text: 'Waitlist Teams',
            sortable: true,
            value: 'waitlistTeams',
            align: 'center'
          },
          {
            text: 'Players',
            sortable: true,
            value: 'players',
            align: 'center'
          }
        ]
      }
      result.totals = {
        gender: 'Total',
        teams: arrSum(result.rows.map(m => m.teams)),
        waitlistTeams: arrSum(result.rows.map(m => m.waitlistTeams)),
        players: arrSum(result.rows.map(m => m.players)),
        max: arrSum(result.rows.map(m => m.max))
      }
      result.excelRows = [
        result.headers.map(h => h.text).join('\t'),
        ...result.rows.map(r => {
          return result.headers.map(h => r[h.value]).join('\t')
        })
      ]
      result.copyString = result.excelRows.join('\n')

      return result
    },
    shirts () {
      const sizes = ['XS', 'S', 'M', 'L', 'XL', '2X'].map(m => {
        return {
          name: m,
          count: 0
        }
      })

      if (this.regData) {
        this.tournament.activePlayers.forEach(m => {
          var data = this.regData.find(f => f.playerProfileId === m.playerProfileId)
          // if (!data) return
          const name = data ? (data.shirtSize || 'NA').toUpperCase() : 'NF'
          let x = sizes.find(f => f.name === name)
          if (!x) {
            x = {
              name: name,
              count: 0
            }
            sizes.push(x)
          }
          x.count++
        })
      }

      const result = {
        rows: sizes,
        headers: [
          {
            text: 'Size',
            sortable: false,
            value: 'name'
          },
          {
            text: 'Count',
            sortable: false,
            value: 'count',
            align: 'center'
          }
        ]
      }
      result.totals = {
        name: 'Total',
        count: arrSum(result.rows.map(m => m.count))
      }
      result.excelRows = [
        result.headers.map(h => h.text).join('\t'),
        ...result.rows.map(r => {
          return result.headers.map(h => r[h.value]).join('\t')
        })
      ]
      result.copyString = result.excelRows.join('\n')

      return result
    },
    weekly () {
      const data = flatten(this.tournament.publicDivisions.map(d => {
        const group = (d.props.find(f => f.startsWith('rg1-')) || d.gender.name).replace('rg1-', '').replace('_', ' ')
        const sub = (d.props.find(f => f.startsWith('rg2-')) || d.division.name).replace('rg2-', '').replace('_', ' ')
        return d.activeTeams.length > 0 ? d.activeTeams.map(t => {
          return {
            gender: d.gender.name,
            division: d.division.name,
            dName: d.name,
            week: moment(t._dtCreated).format('W'),
            group: group,
            sub: sub,
            count: 1
          }
        }) : {
          gender: d.gender.name,
          division: d.division.name,
          dName: d.name,
          week: 0,
          group: group,
          sub: sub,
          count: 0
        }
      }))

      const groups = [...new Set(data.map(m => m.group))]
      const weeks = [...new Set(data.map(m => m.week))].filter(f => !!f).sort((a, b) => a - b)
      console.log('here')

      const results = {
        weeks: weeks,
        groups: groups.map((g, i) => {
          const gData = data.filter(f => f.group === g)
          const subs = [...new Set(gData.map(m => m.sub))]
          return {
            name: g,
            subs: subs,
            color: i % 2 !== 30 ? null : 'grey lighten-5',
            weeks: weeks.map(w => {
              const obj = {
                week: w
              }
              const wData = gData.filter(f => f.week === w)
              subs.forEach(s => {
                const sData = wData.filter(f => f.sub === s)
                obj[s] = arrSum(sData.map(m => m.count))
              })
              obj.total = arrSum(wData.map(m => m.count))
              return obj
            })
          }
        })
      }
      return results
    }
  },
  methods: {
    getRegistrations () {
      this.loading = true
      this.$VBL.tournament.getRegistrationData(this.tournament.id)
        .then((response) => {
          this.regData = response.data
          this.loading = false
        })
        .catch((error) => {
          console.log(error.response)
          this.loading = false
        })
    },
    copy (refName, name) {
      const tbl = this.$refs[refName].$el.getElementsByTagName('table')[0]
      if (!tbl) return
      var textArea = document.createElement('textarea')
      textArea.style.position = 'fixed'
      textArea.style.top = 0
      textArea.style.left = 0
      textArea.style.width = '2em'
      textArea.style.height = '2em'
      textArea.style.padding = 0
      textArea.style.border = 'none'
      textArea.style.outline = 'none'
      textArea.style.boxShadow = 'none'
      textArea.style.background = 'transparent'
      const clone = tbl.cloneNode(true)
      var i = 0
      let found = false
      while (!found && i < tbl.rows.length) {
        const r = tbl.rows[i]
        if (r.className === 'v-datatable__progress') {
          clone.deleteRow(i)
          found = true
        }
        i++
      }

      textArea.value = clone.outerHTML
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        console.log('Copying text command was ' + msg)
      } catch (err) {
        console.log('Oops, unable to copy')
      }

      document.body.removeChild(textArea)
    },
    getWeeklyValue (w, g, s) {
      if (g) {
        const week = g.weeks.find(f => f.week === w)
        return week[s]
      }
      const weeks = flatten(this.weekly.groups.map(g => {
        return g.weeks.filter(f => f.week === w)
      }))
      return arrSum(weeks.map(m => m.total))
    },
    getTotalValue (g, s) {
      const weeks = g.weeks.map(f => f[s])
      return arrSum(weeks)
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        this.getRegistrations()
      }
    }
  },
  mounted () {
    this.getRegistrations()
  }
}
</script>

<style scoped>
.total {
  border-top: 2px solid #000 !important;
}
.total td {
  font-weight: 800;
}
</style>
